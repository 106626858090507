/* global $ */
class App
{
    bootstrap;

    constructor()
    {
        this.menuMoving = false;
        this.disableLogger();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initStickyMenu();
        this.initflyoutMenu();
        this.initForms();
        this.initMatrixBlock();
        $('body').css('opacity', 1);
        console.log('App initialized');
        import(/* webpackChunkName: "slick" */ '../js/components/slick').then(() => {
            $('.header-carousel').slick({
                autoplay: true,
                slidesToShow: 1,
                autoplaySpeed: 5000,
                fade: true,
                speed: 1000,
                pauseOnHover: false,
                arrows: false,
                dots: true
            });
        });
        var _this = this;
        // var scrollCount = 0;
        var ofv = [];
        $(window).scroll(function () {
            // scrollCount++;
            if ($('.article-body').length) {
                var maxlength = $('#article_feed').attr('max-length');
                var offset = $('#article_feed').attr('current-length');
                if (offset < parseInt(maxlength)) {

                    let newvalue = parseInt(offset) + 3;
                    if ($.inArray(newvalue, ofv) === -1) {
                        ofv.push(newvalue);
                        $('#article_feed').attr('current-length', newvalue);
                        _this.loadMore(ofv, newvalue);
                    }

                }
            }
        });
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    loadMore(ofv, newvalue) {
        let catId = $("select[name='search_category']").val();
        var offset = $('#article_feed').attr('current-length');
        var limit = 3;
        //console.log(ofv[ofv.length - 1]);

        $.ajax({
            url: '/ajax/getNews',
            method: 'get',
            data: {
                offset: newvalue - 3,
                limit: limit,
                catId: catId
            }
        }).done((data) => {
            let newvalue = parseInt(offset) + parseInt(limit);
            $('#article_feed').attr('current-length', newvalue);
            let articles = $(data);
            $('#article_feed').append(articles);
        });
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 30;
            if (scrollTop > 300) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }

    initflyoutMenu() {
        $(".flyout-menu-btn").click(function(){
            $(".flyout-menu-area").toggleClass("open");
        });
    }

    initForms()
    {
        if ($('form.js-validate').length) {
            import(/* webpackChunkName: "form" */ './components/form').then(form => {
                $.each($('form.js-validate'), (i, elem) => {
                    new form.Form(elem.id);
                });
            });
        }
        if ($('form.js-has-spinner').length) {
            $('form.js-has-spinner').submit((e) => {
                $(e.target).find('.spinner-border').show();
            });
        }
    }

    initToasts(elems)
    {
        this.getBootstrap().then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Toast(elem);
            });
        });
    }

    initTooltips(elems)
    {
        this.getBootstrap().then(bootstrap => {
            $.each(elems, function (i, elem) {
                new bootstrap.Tooltip(elem);
            });
        });
    }

    initMatrixBlock() {
        if ($('.matrix-content').length) {
            import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
                new matrixblock.Matrixblock;
            });
        }
    }
}

export default App;