/**
 * Import here any npm modules and your own js/scss
 * You can import npm modules as css, scss or js
 * By importing scss you give yourself the ability to override the variables through resources.scss
 */

/**************
 * Javascript
 **************/

//App

import 'lazysizes';
import App from './js/app';

window.App = new App;

import(/* webpackChunkName: "fontawesome" */ './js/components/fontawesome');

/**************
 * Css
 **************/

import "bootstrap/scss/bootstrap.scss";

//App
import "./css/app/base/html.scss";
import "./css/app/base/abp-icon.scss";
import "./css/app/base/typography.scss";
import "./css/app/base/buttons.scss";
import "./css/app/components/header.scss";
import "./css/app/components/nav.scss";
import "./css/app/components/header-banner.scss";
import "./css/app/components/footer.scss";
import "./css/app/components/forms.scss";
import "./css/app/components/my-bookings.scss";
import "lightslider";
import 'lightslider/src/css/lightslider.css';